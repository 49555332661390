import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import { FaFacebook, FaTwitter, FaLinkedin, FaPhone, FaEnvelope } from "react-icons/fa";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate navBG"
        style={
          y > 100
            ? { height: "80px", backgroundColor: "rgba(245, 245, 245, 0.913)" }
            : { height: "90px" }
        }
      >
        <NavInner
          className={`${
            y > 100 ? "container" : "container-fluid"
          } flexSpaceCenter`} 
        >

          <LeftWrapper className="flexNullCenter">
            <Link className="pointer flexNullCenter" to="home" smooth={true}>
            
            </Link>
            {y <= 100 && (
            <SocialIconsWrapper className="me-5">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="fs-5"><FaFacebook /></a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="fs-5"><FaTwitter /></a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="fs-5"><FaLinkedin /></a>
            </SocialIconsWrapper>
            )}
              <LogoIcon />
          </LeftWrapper>
          

          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>

        
          <UlWrapper className="flexNullCenter mt-2 mb-2">
            <li className="semiBold pointer p-2 me-3 lightbg fs-5">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="/"
                spy={true}
                smooth={true}
                offset={-80}
              >
                One2One Scheduler
              </Link>
            </li>
            <li className="semiBold pointer p-2 me-3 lightbg fs-5">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="/event"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Event
              </Link>
            </li>
            <li className="semiBold pointer p-2 me-3 lightbg fs-5">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="/Aboutus"
                spy={true}
                smooth={true}
                offset={-80}
              >
                About Us
              </Link>
            </li>
            <li className="semiBold pointer p-2 me-3 lightbg fs-5">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="/Contact_us"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Contact Us
              </Link>
            </li>
            {/* <li className="semiBold pointer p-2 lightbg fs-5">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="/Contact_us"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Get Started
              </Link>
            </li> */}
          </UlWrapper>

          {/* Right contact info, visible when height is 90px */}
          {y <= 100 && (
            <ContactWrapper className="flexNullCenter">
              <div className="d-flex align-items-center me-3">
                <FaPhone /> <span className="ms-2 fs-6">+91- 99930 53695</span>
              </div>
              <div className="d-flex align-items-center">
                <FaEnvelope /> <span className="ms-2 fs-6">hello@one2onescheduler.com</span>
              </div>
            </ContactWrapper>
          )}
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SocialIconsWrapper = styled.div`
  display: flex;
  margin-left: 20px;

  a {
    font-size: 20px;
    color: #000;
    margin-right: 15px;
  }
`;

const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;

  .d-flex {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }
`;

