import React, { useState, useEffect } from 'react';
import { 
  faVideo, 
  faUsers, 
  faUser,
  faClock,
  faIdBadge,
  faMap,
  faChartBar,
  faCalendarAlt,
  faBullhorn,
  faBan,
  faHourglassHalf,
  faChalkboardTeacher,
  faStar,
  faEnvelopeOpenText,
  faTags
} from '@fortawesome/free-solid-svg-icons';
import Slider from './testimonials';
import Contact from './Contact';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';

const CollapsibleComponent = () => {
  const [activeSection, setActiveSection] = useState('first');  
  const [showAll, setShowAll] = useState(false);  
  const [showPricing, setShowPricing] = useState(false);  // Manage Pricing section visibility

  const activeButtonStyle = {
    border: '1px solid #7620ff',
    backgroundColor: '#7620ff',
    color: 'white',
  };

  const secondaryButtonStyle = {
    border: '1px solid #6c757d', 
    backgroundColor: '#6c757d',    
    color: 'white',
  };

  const cards = [
    { icon: faUsers, description: 'Bulk import companies and delegates information.' },
    { icon: faClock, description: 'Automatically disconnects video calls after the meeting time ends. (Virtual)' },
    { icon: faVideo, description: 'A browser-based video call interface featuring the event logo. (Virtual)' },
    { icon: faUser, description: 'Access and screen all attendees\' profiles.' },
    { icon: faIdBadge, description: 'Identify and follow-up with inactive delegates.' },
    { icon: faMap, description: 'Allocate meeting locations using various methods.' },
    { icon: faChartBar, description: 'Simplified reporting system for event statistics.' },
    { icon: faCalendarAlt, description: 'Easily schedule meetings with attendees.' },
    { icon: faBullhorn, description: 'Advertise and promote event sponsors effectively.' },
    { icon: faBan, description: 'Prevent duplicate meetings from being created.' },
    { icon: faHourglassHalf, description: 'Set variable meeting time slots as needed.' },
    { icon: faChalkboardTeacher, description: 'Provide a dedicated space for workshops.' },
    { icon: faStar, description: 'Exclusive branding opportunities for premium sponsors.' },
    { icon: faEnvelopeOpenText, description: 'Send real-time email notifications.' },
    { icon: faTags, description: 'Showcase sponsors prominently during the event.' }
  ];

  const pricingPlans = [
    { id: 1, name: 'Basic Plan', price: '$19.99', description: 'Ideal for individuals and small teams.' },
    { id: 2, name: 'Pro Plan', price: '$49.99', description: 'Perfect for medium-sized teams with additional features.' },
    { id: 3, name: 'Enterprise Plan', price: '$99.99', description: 'For large organizations needing full access to all features.' },
  ];

  useEffect(() => {
    setShowAll(false);  
  }, [activeSection]);

  return (
    <div className="container mt-" data-aos="fade-up" style={{ minHeight: '70vh' }}>
      <h2 className="text-center meeting-title pb-5 fs-3">Why Choose Our Meeting Scheduler?</h2>
      <div className="row mb-3 d-flex justify-content-center">
        <div className="col-auto">
          <button 
            className="btn btn-lg"
            onClick={() => setActiveSection('first')}
            style={activeSection === 'first' ? activeButtonStyle : secondaryButtonStyle}
          >
            Features
          </button>
        </div>
        <div className="col-auto">
          <button 
            className="btn btn-lg"
            onClick={() => setActiveSection('second')}
            style={activeSection === 'second' ? activeButtonStyle : secondaryButtonStyle}
          >
            Testimonials
          </button>
        </div>
        <div className="col-auto">
          <button 
            className="btn btn-lg"
            onClick={() => setActiveSection('pricing')}
            style={activeSection === 'pricing' ? activeButtonStyle : secondaryButtonStyle}
          >
            Pricing
          </button>
        </div>
      </div>

      <div className="card card-body mt-3 p-5 border-0 ">
        {activeSection === 'first' && (
          <div>
            <div className="row justify-content-center">
              {cards.slice(0, showAll ? cards.length : 6).map((card, index) => (
                <div className="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center" key={index}>
                  <div className="custom-card p-4 w-100">
                    <div className="custom-card-body">
                      <div className="custom-icon-container">
                        <FontAwesomeIcon icon={card.icon} size="3x" />
                      </div>
                      <p className="custom-card-text">{card.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          
            {!showAll && (
              <div className="text-center">
                <button className="btn btn-primary btn-theme" onClick={() => setShowAll(true)}>
                  View More
                </button>
              </div>
            )}
          </div>
        )}

        {activeSection === 'second' && (
          <div>
            <Slider />
          </div>
        )}

        {activeSection === 'pricing' && (
          <div>
            
              <Contact/>
          
          </div>
        )}
      </div>
    </div>
  );
};

export default CollapsibleComponent;
