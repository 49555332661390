import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faGoogle, faInstagram, faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone, faPrint, faHome } from "@fortawesome/free-solid-svg-icons";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div className=" my-5" data-aos="fade-up">
      {/* Footer */}
      <footer
        className="text-center text-lg-start text-white"
        style={{ backgroundColor: "#1c2331" }}
      >
        {/* Section: Social media */}
        <section
          className="d-flex justify-content-between p-4 curve"
          style={{ backgroundColor: "#f5f5f5" }}
        >
          {/* Left */}
          <div className="me-5">
            <span></span>
          </div>
          {/* Right */}
          <div>
            
          </div>
        </section>
        {/* Section: Social media */}

        {/* Section: Links */}
        <section>
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              {/* Company Name */}
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4 mt-5">
                <h6 className="text-uppercase fw-bold">One2One Scheduler</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                />
                <p>
                It was launched in 2010 to maximize the networking benefits for the delegates and ease of organization for organizing an event through its simple and actively supported One2One Meeting Scheduler and Event Solutions.
                </p>
              </div>

              {/* Products */}
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 mt-5">
                <h6 className="text-uppercase fw-bold">Other links</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                />
                <p>
                  <a href="#!" className="text-white">
                  Terms and Conditions

                  </a>
                </p>
                <p>
                  <a href="#!" className="text-white">
                  Privacy Policy
               
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-white">
                  Testimonial
                  </a>
                </p>
                <p>
                  {/* <a href="#!" className="text-white">
                    Bootstrap Angular
                  </a> */}
                </p>
              </div>

              {/* Useful Links */}
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 mt-5">
                <h6 className="text-uppercase fw-bold">Useful links</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                />
                <p>
                  <a href="#!" className="text-white">
                    Your Account
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-white">
                    Become an Affiliate
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-white">
                    Shipping Rates
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-white">
                    Help
                  </a>
                </p>
              </div>

              {/* Contact */}
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 mt-5">
                <h6 className="text-uppercase fw-bold">Contact</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                />
                <p>
                  <FontAwesomeIcon icon={faHome} /> 405, The Mark, 20-A, Anand Bazaar Square, Old Palasia, Indore, Madhya Pradesh 452001
                </p>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} /> hello@one2onescheduler.com
                </p>
                <p>
                  <FontAwesomeIcon icon={faPhone} /> +91 99930 53695
                </p>
                {/* <p>
                  <FontAwesomeIcon icon="print" /> +01 234 567 89
                </p> */}
              </div>
            </div>
          </div>
        </section>
        {/* Section: Links */}

        {/* Footer Bottom */}
        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          © {getCurrentYear()} Copyright:
          <a className="text-white" href="https://react.tsevent.app/">
            techSimba.com
          </a>
        </div>
      </footer>
    </div>
  );
}
