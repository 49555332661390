import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; 

const Slider = () => {
  const testimonials = [
    {
      logo: "https://one2onescheduler.com/wp-content/uploads/2022/04/gfp-1.png",
      description: "I recently started using the OTO1 Meeting Scheduler, and I must say it has made scheduling meetings a breeze! The interface is user-friendly and very intuitive.",
      companyName: "Global Forwarding Partners Inc",
      clientName: "Carl and Richard"
    },
    {
      logo: "https://one2onescheduler.com/wp-content/uploads/2022/04/cc.png",
      description: " CTN Group AGM. Thanks so much for your wonderful software.In preparation for next year, our conference will be Mid October in Germany and we will use your software again",
      companyName: "Certified Transportation Network",
      clientName: "Lynn Sessa"
    },
    {
      logo: "https://one2onescheduler.com/wp-content/uploads/2022/04/g-1.png",
      description: "1st International Freight Forwarders & Logistics Cooperation Forum.It is quite easier to use than other. Conference attendees would like to check all the countries, cities",
      companyName: "Glocal Logistics Web Limited",
      clientName: "Sally Ho"
    },
    {
      logo: "https://one2onescheduler.com/wp-content/uploads/2022/04/cf.png",
      description: "Testimonial description here.",
      companyName: "CILF Networking Days One2One Meetings.I think this scheduler is wonderful",
      clientName: "Vivienne"
    },{
      logo: "https://one2onescheduler.com/wp-content/uploads/2022/04/presidence.jpg",
      description: "2nd GLW International Freight & Logistics Conference.The one to one meeting scheduler is easiest software I have used it. I like it very much.",
      companyName: "Glocal Logistics Web Limited",
      clientName: "Sally Ho"
    },
    {
      logo: "https://one2onescheduler.com/wp-content/uploads/2022/04/ne-1.png",
      description: " PLN 2nd Annual Conference.The one to one meeting scheduler was great! We will surely be using this system on our future Conference/s.",
      companyName: "PLANET LOGISTICS NETWORK",
      clientName: "Tariq Faizan Sabri"
    },
    {
      logo: "https://one2onescheduler.com/wp-content/uploads/2022/04/gfp-1.png",
      description: "8th AGM .Great system. It worked very well and makes the scheduling our meetings so much easier and more accurate",
      companyName: "Global Forwarding Partners Inc.",
      clientName: "Stephen McKittrick"
    },
    {
      logo: "https://one2onescheduler.com/wp-content/uploads/2022/04/co-1.png ",
      description: "Testimonial description here.",
      companyName: "Company D",
      clientName: "Client W"
    }
  ];

  return (
    <div className="container mt-5" data-aos="fade-up">
      <div className="testimonial-slider-wrapper">
        <div className="testimonial-slider">
          {testimonials.map((testimonial, index) => (
            <div className="testimonial-card" key={index}>
              <div className="testimonial-logo mb-3">
                <img src={testimonial.logo} alt="Client Logo" className="img-fluid rounded-circle" style={{ width: '100px', height: '100px' }} />
              </div>
              <div className="testimonial-description mb-3">
                <p className="text-muted fw-bold">{testimonial.description}</p>
              </div>
              <div className="testimonial-footer">
                <p className="font-weight-bold">{testimonial.companyName}</p>
                <p className="text-secondary">{testimonial.clientName}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;
