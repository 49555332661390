import ReactDOM from "react-dom/client";
import { BrowserRouter, Route,Routes } from "react-router-dom";
import Template from "./components/layout/Template";
import Example from "./screens/Example";
import ContactHeader from "./components/Contact/ContactHeader";
import Aboutus from "./components/AboutUs/Aboutus";
import EventHeader from "./components/Event/EventHeader";
import EventHeader2 from "./components/Event/EventHeader2";
import Contact_us from "./components/Contact/contact_us";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


export default function RouteApp() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]); 
  
    return null;
  };
  return (
    <BrowserRouter>
     <ScrollToTop />
      <Routes>
     
        <Route path="/" element={<Template><Example/></Template>}/>
        <Route path="/ContactHeader" element={<Template><ContactHeader/></Template>}/>
        <Route path="/Aboutus" element={<Template><Aboutus/></Template>}/>
        <Route path="/event" element={<Template><EventHeader/></Template>}/>
        <Route path="/EventHeader2" element={<Template><EventHeader2/></Template>}/>
        <Route path="/Contact_us" element={<Template><Contact_us/></Template>}/>
     
      </Routes>
    </BrowserRouter>
  );
}

