import React from 'react';
import ReactDOM from 'react-dom/client'; // Use createRoot from ReactDOM
import App from './App';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import './style/index.css';

import reportWebVitals from './reportWebVitals';

// Create the root
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the application
root.render(
  <>
    <App />
  </>
);

// Optional: for measuring performance
reportWebVitals();
