import React, { useState } from 'react';
import styled from "styled-components";
import '../../style/EventHeader2.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faUsers, faVideoCamera } from '@fortawesome/free-solid-svg-icons';
// Components
import FullButton from "../Buttons/FullButton";
// import BlogBox from "../Elements/BlogBox";
import imageOneToOne from '../../assets/event/image_resized.jpg';

const cards = [
  {
    icon: faVideo, 
    title: 'Administrator', 
    description: 'Hire a dedicated and monitored event administrator responsible for planning, communication, and digital information management.'
  },
  {
    icon: faUsers, 
    title: 'Event App', 
    description: 'It is a must-have for your next event as it reduces the need to print, allows quick access to recent event information, increases interaction, and provides monetization areas.'
  },
  {
    icon: faVideoCamera, 
    title: 'Webmaster', 
    description: 'We cater to your needs regarding post-event activities like optimizing and uploading photos and videos to post-event galleries, designing and collecting surveys, and a thank-you e-mail to all the attendees, etc.'
  },
  {
    icon: faVideo, 
    title: 'Registration Form', 
    description: 'The Registration Form provides areas to collect personal and contact information of the attendees, the necessary attendance information for the event, and allows you to collect registration fees. We ensure straightforward, accurate, and lossless data collection. Plus, the system will automatically send e-mail notifications to both the delegate and event organizer on every successful registration.'
  },
  {
    icon: faUsers, 
    title: 'Event Website', 
    description: 'An event needs a website, which prospects can refer to for the event information, register as a delegate, get the latest event updates, and remain in touch with the event administrator. All the traffic generated by promotions will be redirected to the website; thus, an event website must have mobile-friendly, fast-loading, and recent content.'
  },
  {
    icon: faVideoCamera, 
    title: 'Digital Promotion', 
    description: 'Whether your event is only for the network members or open to the public, you will need event promotion services to increase network members and the number of delegates attending the event. An experienced and knowledgeable team of digital marketers at One2One is ready to create a successful buzz for your upcoming event.'
  },
  {
    icon: faVideoCamera, 
    title: 'Event Management System (EMS)', 
    description: 'A platform that makes it easy for administrators to track delegate information and various statuses like payment, hotel booking, travel schedule, etc. It also provides a mechanism to broadcast messages to all or specific groups or individuals.'
  },
];
const EventHeader2 = () => {

return(
    <div>
    <Wrapperr data-aos="fade-up">
        <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="">
                <img
                    className="radius8 mb-5"
                    src={imageOneToOne} alt="add" style={{ borderRadius: "20px",border: "3px solid grey",}}
                    />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
            <h2 className="font40 extraBold">Boost Your Event</h2>
                <p className="">
                We offer event websites, apps, promotion, support, and management tools, 
                ensuring that your event will grab more attention digitally and provide excellent User experience (UX).
                </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Get Started" action={() => alert("clicked")} />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title="Contact Us" action={() => alert("clicked")} border />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>

      </Wrapperr>

      <Wrapper id="blog">
      <div className="whiteBg " style={{ marginTop: "5%"}}>
        <div className="container" >
          <HeaderInfo>
          <div className="col-xs-8" >
            <h1 className="font40 extraBold mb-2">Event</h1>
            <p className="mb-2">
            Lots of effort and meticulous planning are required for any business event to be flawless and memorable for its attendees. 
            The team at One2OneScheduler.com has developed an event platform or software tools and has extensive experience administering the conferences.
            </p>
            <p className="">
            We do offer the below-mentioned software tools and services:
            </p>
            </div>
          </HeaderInfo>
          <div className='mt-5'>
    <div className="container-fluid mt-5 ">
    <div className="text-center " >
      <h3 className="meeting-title p-5 fs-3"></h3>
    </div>
  
    <div className="row justify-content-center">
      {cards.map((card, index) => (
        <div
          className="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center"
          key={index}
          style={{ marginBottom: '30px' }}
        >
          <div className="custom-card p-4">
            <div className="custom-card-body">
              <div className="custom-icon-container">
                <FontAwesomeIcon icon={card.icon} size="3x" />
              </div>
              <h5 className="custom-card-title">{card.title}</h5>
              <p className="custom-card-text">{card.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
  </div>
        </div>
      </div>

    </Wrapper>
    </div>
    

 );
};
const Wrapperr = styled.section`
  margin-top: 20%;
    @media (max-width: 1200px) {
    margin-top: 20%;
  }

  @media (max-width: 992px) {
    margin-top: 25%;
  }

  @media (max-width: 768px) {
    margin-top: 30%;
  }

  @media (max-width: 576px) {
    margin-top: 35%;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const ImgWrapper = styled.div`
  width: 103%;
  padding: 0 15%;

  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
const AddRight = styled.div`
  width: 50%;
  padding: 0 2%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
// blog
const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const StyledBlogBox = styled.div`
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
  transition: all 0.3s ease-in-out;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10%;
  height: 100%;
  position: relative;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transform: scale(1.05);
  }

  .icon {
    font-size: 3rem;
    margin-bottom: 15px;
  }

  .title {
    font-size: 1.25rem;
    // font-weight: bold;
  }

  .text {
    color: #605e5e;
  }

  .tag {
    position: absolute;
    bottom: 10px; /* Moves it to the bottom */
    left: 7%; /* Aligns it to the left */
    background-color: #c4ffff;
    color: #2C2B2BFF;
    border-radius: 20px;
    font-weight: bold;
    font-size: 0.875rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 5px 15px; /* Adjusts padding */
  }
`;




export default EventHeader2;