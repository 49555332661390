import React from "react";
import { useForm } from "react-hook-form";
import FullButton from "../Buttons/FullButton"; 
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaSkype } from "react-icons/fa"; 
import "../../style/ContactDetails.css";

const Contact_us = () => {

 

  const { register, handleSubmit, formState: { errors } } = useForm(); 


  const onSubmit = (data) => {
    const formData = new FormData();
  
    
    for (const key in data) {
      formData.append(key, data[key]);
    }
  
    console.log("Form data submitted:", data);
  
   
    fetch('https://clubnet.app/admin_one2one/Member/', {
      method: 'POST', 
      body: formData, 
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((responseData) => {
        console.log('Server response:', responseData);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };
  

  return (
    <>
      <div className="container-fluid p-0" data-aos="fade-up">
        {/* Hero Section */}
        <div
          className="text-center d-flex align-items-center justify-content-center"
          style={{
            marginTop: "80px",
            backgroundImage: "url('/contactus.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "300px",
            color: "white",
            position: "relative",
          }}
        >
          {/* Overlay */}
          <div
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          ></div>
          <div style={{ zIndex: 1 }}>
            <h1 className="display-4 fw-bold">Contact Us</h1>
          </div>
        </div>

        {/* Main Content */}
        <div className="container py-5">
          <div className="row gy-4">
            {/* Contact Information Section */}
            <div className="col-md-7" data-aos="fade-up">
              <h3 className="fw-bold mb-4">Contact Information</h3>
              <p className="mb-4 text-muted">
                Give us a call or drop by anytime, we endeavour to answer all
                enquiries within 24 hours on business days. One2One Meeting
                Scheduler and Event Software is developed and maintained by
                Techsimba Pvt. Ltd.
              </p>

              <div className="row gy-4 contact-info mt-2 justify-content-center">
                {/* Address */}
                <div className="custom-card col-md-5 col-12  mx-1 p-2 row rounded h-150 mb-2">
                  <div className="d-flex">
                    <div className="align-self-center">
                      <FaMapMarkerAlt className="text-primary icon-hover me-3" size={40} />
                    </div>
                    <div className="col-md-9 text-start p-1 align-self-center">
                      <h5 className="fw-bold">Our Address</h5>
                      <p className="mb-0 pg-14">
                        405, The Mark, 20-A, Old Palasia, Anand Bazaar Square,
                        Indore 452018 (MP) India
                      </p>
                    </div>
                  </div>
                </div>

                {/* Phone */}
                <div className="custom-card col-md-5 col-12  mx-1 p-2 row rounded h-150">
                  <div className="d-flex">
                    <div className="align-self-center">
                      <FaPhoneAlt className="text-primary icon-hover me-3" size={40} />
                    </div>
                    <div className="col-md-9 text-start p-1 align-self-center">
                      <h5 className="fw-bold">Phone Number</h5>
                      <p className="mb-0 pg-14">9993053695</p>
                    </div>
                  </div>
                </div>

                {/* Skype */}
                <div className="custom-card col-md-5 col-12  mx-1 p-2 row rounded h-150">
                  <div className="d-flex">
                    <div className="align-self-center">
                      <FaSkype className="text-primary icon-hover me-3" size={40} />
                    </div>
                    <div className="col-md-9 text-start p-1 align-self-center">
                      <h5 className="fw-bold">Skype</h5>
                      <p className="mb-0 pg-14">One2OneScheduler</p>
                    </div>
                  </div>
                </div>

                {/* Email */}
                <div className="custom-card col-md-5 col-12  mx-1 p-2 row rounded h-150">
                  <div className="d-flex">
                    <div className="align-self-center">
                      <FaEnvelope className="text-primary icon-hover me-3" size={40} />
                    </div>
                    <div className="col-md-9 text-start p-1 align-self-center">
                      <h5 className="fw-bold">Email</h5>
                      <p className="mb-0 pg-14">hello@one2onescheduler.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Appointment Form */}
            <div className="col-md-5" data-aos="fade-up">
              <div className="custom-card p-4 shadow">
                <h3 className="fw-bold mb-4 text-center">
                  We would love to hear from you!
                </h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                      {...register("name", { required: "Name is required" })}
                    />
                    {errors.name && <p className="text-danger">{errors.name.message}</p>}
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Company Name"
                      {...register("companyName", { required: "Company name is required" })}
                    />
                    {errors.companyName && <p className="text-danger">{errors.companyName.message}</p>}
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                      {...register("email", { required: "Email is required", pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/i })}
                    />
                    {errors.email && <p className="text-danger">{errors.email.message}</p>}
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Contact Number"
                      {...register("contactNumber", { required: "Contact number is required" })}
                    />
                    {errors.contactNumber && <p className="text-danger">{errors.contactNumber.message}</p>}
                  </div>
                  <div className="mb-3">
                    <textarea
                      className="form-control"
                      rows="5"
                      placeholder="Write Message..."
                      {...register("message", { required: "Message is required" })}
                    ></textarea>
                    {errors.message && <p className="text-danger">{errors.message.message}</p>}
                  </div>
                  <button className="btn btn-light btn-sm btn-theme w-100" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "300px" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=..."
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Google Map"
        ></iframe>
      </div>
    </>
  );
};

export default Contact_us;
