import React from "react";
import FullButton from "../Buttons/FullButton";



export default function Header() {
  return (
    <>
    <section id="home" className="container-fluid p-0" data-aos="fade-up">
    
      <div className="position-relative w-100" style={{ height: "80vh" }}>
    
        <img
          src="https://png.pngtree.com/background/20230517/original/pngtree-several-business-men-and-women-sitting-around-a-conference-table-picture-image_2634547.jpg"
          alt="Business"
          className="w-100 h-100 object-fit-cover position-absolute top-0 start-0 image-animation"
        />

        <div className="position-absolute top-15 start-50 translate-middle text-white text-center px-3">
          <h1 className="fw-bold fs-1 mb-4 text-dark text-animation">
            Enhance Business Networking...
          </h1>
          <p className="fs-5 mb-4 text-dark paragraph-animation">
            One2One Meeting Scheduler has become a proven tool for event organizers who wish to enhance business networking. Meetings can be pre-scheduled ahead of time to enable event delegates to efficiently network and grow their business.
          </p>
          {/* <div className="d-flex justify-content-center">
            <FullButton title="Schedule One2One Demonstration" />
          </div> */}
        </div>
      </div>
    </section>
  
    </>
  );
}
