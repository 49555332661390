import React from "react";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa"; // Icons for contact info

const ContactHeader = () => {
  return (
    <div className="container-fluid p-0" data-aos="fade-up">
      {/* Hero Section with Enhanced Style */}
      <div
        className="text-center d-flex align-items-center justify-content-center"
        style={{
          marginTop: "80px", // Push content below the navbar
          backgroundImage: "url('https://via.placeholder.com/1920x400')", // Replace with your banner image URL
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "400px",
          position: "relative",
          color: "white",
        }}
      >
        {/* Semi-transparent overlay for better text contrast */}
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          }}
        ></div>

        {/* Hero Text */}
        <div style={{ zIndex: 1 }}>
          <h1 className="display-4 fw-bold">Get in Touch</h1>
          <p className="lead">We are here to help you with any queries</p>
        </div>
      </div>

      {/* Main Content */}
      <div className="container my-5">
        <div className="row g-5">
          {/* Contact Information Section */}
          <div className="col-md-6">
            <h3 className="fw-bold mb-4">TechSimba Pvt. Ltd.</h3>
            <div className="d-flex align-items-start mb-4">
              <FaMapMarkerAlt className="me-3 text-primary" size={24} />
              <div>
                <h5 className="mb-1 fw-bold">Our Address</h5>
                <p className="mb-0">
                  405, The Mark, 20-A, Old Palasia, <br />
                  Anand Bazaar Square, Indore 452018 (MP) India
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start mb-4">
              <FaPhoneAlt className="me-3 text-primary" size={24} />
              <div>
                <h5 className="mb-1 fw-bold">Phone</h5>
                <p className="mb-0">
                  <a href="tel:9993053695" className="text-decoration-none text-dark">
                    9993053695
                  </a>
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <FaEnvelope className="me-3 text-primary" size={24} />
              <div>
                <h5 className="mb-1 fw-bold">Email</h5>
                <p className="mb-0">
                  <a href="mailto:hello@one2onescheduler.com" className="text-decoration-none text-dark">
                    hello@one2onescheduler.com
                  </a>
                </p>
              </div>
            </div>
          </div>

          {/* Contact Form Section */}
          <div className="col-md-6">
            <h3 className="fw-bold mb-4">Send Us a Message</h3>
            <form>
              <div className="row g-3">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    id="companyname"
                    placeholder="Company Name"
                  />
                </div>
              </div>
              <div className="mt-3">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Your Email"
                />
              </div>
              <div className="mt-3">
                <input
                  type="number"
                  className="form-control"
                  id="contact"
                  placeholder="Your Contact Number"
                />
              </div>
              <div className="mt-3">
                <textarea
                  className="form-control"
                  id="message"
                  rows="5"
                  placeholder="Your Message"
                ></textarea>
              </div>
              <div className="mt-4">
                <button type="submit" className="btn btn-primary w-100">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactHeader;
