import React, { useState, useEffect } from "react";
import FullButton from "../Buttons/FullButton";
import ClientSlider from "../Elements/ClientSlider";
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS library


export default function MiddleHeader() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [
    "https://img.freepik.com/free-vector/flat-people-business-training-illustration_23-2148921811.jpg?t=st=1733900295~exp=1733903895~hmac=e4f730bacd11d2024ed2732ec19071487bb246c39170747c4117ed4cf0ea82fd&w=1380",
    "https://img.freepik.com/premium-vector/businessmen-businesswomen-meeting_1214-344.jpg?w=1060",
    "https://img.freepik.com/free-vector/social-distancing-meeting_23-2148559595.jpg?t=st=1733900359~exp=1733903959~hmac=30e23142b1c25f629a08bccf1131e7b2806da8a636b8fc518cbf73d42e218f0d&w=1380",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [images.length]);
  useEffect(() => {
    AOS.init({
      once: false, 
      offset: 200, 
      duration: 1000, 
    });
  }, []);


  const handlePrev = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + images.length) % images.length
    );
  };

  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  return (
    <>
      <div className="lightBg" style={{ padding: "50px 0" }}> 
        <div className="container" data-aos="fade-up">
          <ClientSlider />
        </div>
      </div>

      <section id="home" className="container pt-5 mt-5 mb-5" data-aos="fade-up">
        <div className="row align-items-center">
          {/* Left Side */}
          <div className="col-lg-6 d-flex flex-column justify-content-center align-items-lg-start text-center text-lg-start mb-5 mb-lg-0 mt-4">
            <h1 className="extraBold fs-1">Enhance Business Networking.</h1>
            <p className="fs-5  mb-4 mt-5">
              One2One Meeting Scheduler has become a proven tool for event
              organizers who wish to enhance business networking. Meetings can
              be pre-scheduled ahead of time to enable event delegates to
              efficiently network and grow their business.
            </p>
            <div className="mt-4 mb-3">
              <FullButton title="Schedule One2One Demonstration" />
            </div>
          </div>

          <div className="col-lg-6">
            <div
              id="imageCarousel"
              className="carousel slide"
              data-bs-ride="false"
            >
              <div className="carousel-inner">
                {images.map((image, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${
                      index === currentSlide ? "active" : ""
                    }`}
                  >
                    <img
                      src={image}
                      className="d-block w-100 img-fluid"
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
