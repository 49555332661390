import React from "react";
import styled from "styled-components";
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialScroller() {
  return (
    <ScrollerWrapper data-aos="fade-up">
      <Scroller>
        
            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/mynetwork.png"
                text="1st Annual Meeting 2024 - Bangkok, Thailand"
                author="Delegate Link"
                date="Wednesday 25 September 2024 -  Friday 27 September 2024"
              />
            </LogoWrapper>
            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/african.png"
                text="TFS 15th Global Conference and Emerge 2nd Annual Partnership Meeting - Dubai, United Arab Emirates"
                author="Delegate Link"
                date="Monday 23 September 2024 -Thursday 26 September 2024"
              />
            </LogoWrapper>
            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/hcl.png"
                text="HCL ANNUAL GENERAL MEETING 2024 - Athens, Greece"
                author="Delegate Link"
                date="Wednesday 25 September 2024 - Saturday 28 September 2024"
              />
            </LogoWrapper>
            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/afbn.png"
                text="6th Annual Global Meeting 2024 - Nairobi, Kenya"
                author="Delegate Link"
                date="Wednesday 25 September 2024 - Sunday 29 September 2024"
              />
            </LogoWrapper>

            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/galaxy.png"
                text="2ND ANNUAL GLOBAL MEETING - Phuket Thailand"
                author="Delegate Link"
                date="Monday 07 October 2024 - T  hursday 10 October 2024"
              />
            </LogoWrapper>
            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/ifa.png"
                text="IFA Network"
                author="Delegate Link"
                date="Friday 18 October 2024 - Sunday 20 October 2024"
              />
            </LogoWrapper>
            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/1on1.png"
                text="IFA Network"
                author="Delegate Link"
                date="Friday 18 October 2024 - Sunday 20 October 2024"
              />
            </LogoWrapper>
            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/millian.png"
                text="GKF Network"
                author="Delegate Link"
                date="Tuesday 17 September 2024 - Friday 20 September 2024"
              />
            </LogoWrapper>

            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/planet.png"
                text="Planet Logistics Network & Worldwide 3PL Network Annual Conference 2024."
                author="Delegate Link"
                date="Tuesday 05 November 2024 - Friday 08 November 2024"
              />
            </LogoWrapper>
            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/global.png"
                text="LFS GROUP 31TH ANNUAL GENERAL MEETING - Guangzhou, China"
                author="Delegate Link"
                date="Saturday 14 September 2024 - Tuesday 17 September 2024"
              />
            </LogoWrapper>
            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/fps.png"
                text="FPS AGM 2024 - Phnom Penh, Cambodia"
                author="Delegate Link"
                date="Saturday 12 October 2024 - Wednesday 16 October 2024"
              />
            </LogoWrapper>
            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/cross.png"
                text="CROSSTRADES OBL CONFERENCE - New Delhi"
                author="Delegate Link"
                date="Monday 22 January 2024 - Thursday 25 January 2024"
              />
            </LogoWrapper>

            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/un.png"
                text="UN @ 28th INTERMODAL BRAZIL 2024 - Sao Paulo, Brazil"
                author="Delegate Link"
                date="Tuesday 05 March 2024 - Thursday 07 March 2024"
              />
            </LogoWrapper>
            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/cirtificate.png"
                text="2024 AGM - Athens"
                author="Delegate Link"
                date="Wednesday 08 May 2024 - Friday 10 May 2024"
              />
            </LogoWrapper>

            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/bling.png"
                text="Bling Panama Summit - Panama City"
                author="Delegate Link"
                date="Sunday 17 March 2024 - Thursday 21 March 2024"
              />
            </LogoWrapper>
            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/fl.png"
                text="Freight Lounge Annual Global Meeting 2024 - Langkawi"
                author="Delegate Link"
                date="Friday 10 May 2024 - Tuesday 14 May 2024"
              />
            </LogoWrapper>
            <LogoWrapper >
              <TestimonialBox
              logo="/Testimonial/global1.png"
                text="GFG AGM 2024 - Atlanta, Georgia"
                author="Delegate Link"
                date="Sunday 22 September 2024 - Tuesday 24 September 2024"
              />
            </LogoWrapper>
            
        
      </Scroller>
    </ScrollerWrapper>
  );
}

const ScrollerWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  padding: 1.4rem;
 
`;


const Scroller = styled.div`
  display: flex;
  gap: 20px; /* Spacing between testimonials */
  animation: scrollLeft 20s linear infinite;

  @keyframes scrollLeft {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-100%);
    }
  }
`;

const LogoWrapper = styled.div`
  flex: 0 0 auto;
  width: 300px; /* Set a consistent width for the testimonials */
  cursor: pointer;

  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
