import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import ClientLogo01 from "../../assets/img/clients/3PL_N.png";
import ClientLogo02 from "../../assets/img/clients/7.png";
import ClientLogo03 from "../../assets/img/clients/8.png";
import ClientLogo04 from "../../assets/img/clients/13.png";
import ClientLogo05 from "../../assets/img/clients/2017_N.png";
import ClientLogo06 from "../../assets/img/clients/A8.png";
import ClientLogo07 from "../../assets/img/clients/AD_N.png";
import ClientLogo08 from "../../assets/img/clients/ASA_Network.png";
import ClientLogo09 from "../../assets/img/clients/CILF_NETOWRK.png";
import ClientLogo10 from "../../assets/img/clients/CLC_NETWORK.png";
import ClientLogo11 from "../../assets/img/clients/CONQUEERER_NETWORK.png";
import ClientLogo12 from "../../assets/img/clients/CTF_N.png";
import ClientLogo13 from "../../assets/img/clients/DA_N.png";
import ClientLogo14 from "../../assets/img/clients/FNC_N.png";
import ClientLogo15 from "../../assets/img/clients/GFP_N.png";
import ClientLogo16 from "../../assets/img/clients/GG.png";
import ClientLogo17 from "../../assets/img/clients/GIG_NETWORK.png";
import ClientLogo18 from "../../assets/img/clients/GLA_NETOWRK.png";
import ClientLogo19 from "../../assets/img/clients/IDA_N.png";
import ClientLogo20 from "../../assets/img/clients/IFLN_Network.png";
import ClientLogo21 from "../../assets/img/clients/IG_N.png";
import ClientLogo22 from "../../assets/img/clients/INT.png";
import ClientLogo23 from "../../assets/img/clients/IWF_N.png";
import ClientLogo24 from "../../assets/img/clients/LLN_N.png";
import ClientLogo25 from "../../assets/img/clients/MRO_N.png";
import ClientLogo26 from "../../assets/img/clients/NAF_NN.png";
import ClientLogo27 from "../../assets/img/clients/PA.png";
import ClientLogo28 from "../../assets/img/clients/PN_N.png";
import ClientLogo29 from "../../assets/img/clients/PPL_NETWORK.png";
import ClientLogo30 from "../../assets/img/clients/SI_N.png";
import ClientLogo31 from "../../assets/img/clients/TCF_N.png";
import ClientLogo32 from "../../assets/img/clients/UNN_N.png";
import ClientLogo33 from "../../assets/img/clients/WAN_N.png";
import ClientLogo34 from "../../assets/img/clients/WBN_N.png";
import ClientLogo35 from "../../assets/img/clients/WFP_NETWORK.png";
 
const clientLogos = [
  ClientLogo01,
  ClientLogo02,
  ClientLogo03,
  ClientLogo04,
  ClientLogo05,
  ClientLogo06,
  ClientLogo06,
  ClientLogo07,
  ClientLogo08,
  ClientLogo09,
  ClientLogo10,
  ClientLogo11,
  ClientLogo12,
  ClientLogo13,
  ClientLogo14,
  ClientLogo15,
  ClientLogo16,
  ClientLogo17,
  ClientLogo18,
  ClientLogo19,
  ClientLogo20,
  ClientLogo21,
  ClientLogo22,
  ClientLogo23,
  ClientLogo24,
  ClientLogo25,
  ClientLogo26,
  ClientLogo27,
  ClientLogo28,
  ClientLogo29,
  ClientLogo30,
  ClientLogo31,
  ClientLogo32,
  ClientLogo33,
  ClientLogo34,
  ClientLogo35,
];

export default function ClientSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        {clientLogos.map((logo, index) => (
          <LogoWrapper key={index} className="flexCenter">
            <ImgStyle src={logo} alt="client logo" />
          </LogoWrapper>
        ))}
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 100px;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;

const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;
