import React from "react";
import styled from "styled-components";
// Assets
import QuoteIcon from "../../assets/svg/Quotes";
import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";

export default function TestimonialBox({ text, author, logo,date }) {
  return (
    <Wrapper className="darkBg radius8 flexNullCenter flexColumn p-4 h-100">
      {/* <QuoteWrapper>
        <QuoteIcon />
      </QuoteWrapper> */}
      {/* Logo at the center */}
      
        <img src={logo} alt="Logo"style={{borderRadius:'5%'}} className="logo mb-2 w-75" />
    
      <p className="darkColor font15" style={{ paddingBottom: "30px" }}>
        {text}
      </p>
      <p className="darkColor m font15 pb-4 " >
        {date}
      </p>
    
      {/* Author with animated arrow */}
      <button className="btn btn-secondary purpleBg btn-sm w-100 " >
  <AnimatedAuthor>
    <span className="fw-bold">{author}</span>
    <span className="arrow fs-2">→</span>
  </AnimatedAuthor>
</button>

    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  margin-top: 30px;
   transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05); /* Zoom effect */
  }
`;
const QuoteWrapper = styled.div`
  position: relative;
  top: -40px;
`;
const AnimatedAuthor = styled.div`
  .animated-author {
    display: flex;
    align-items: center;
  }

  .arrow {
    display: inline-block;
    margin-left: 10px;
   
   
    animation: moveArrow 1s infinite;

    @keyframes moveArrow {
      0% {
        transform: translateX(0);
      }
      50% {
        transform: translateX(5px);
      }
      100% {
        transform: translateX(0);
      }
    }
  }
`;