import React from "react";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import 'aos/dist/aos.css';
import AOS from 'aos'; 
import { useEffect } from "react";

// Screens
import RouteApp from "./RouteApp.js";

export default function App() {
  useEffect(() => {
    AOS.init({
      once: false, 
      offset: 200, 
      duration: 1000, 
    });
  }, []);
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <RouteApp/>
    </>
  );
}

