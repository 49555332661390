import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faUsers,
  faLaptopCode,
  faCalendarAlt,
  faNetworkWired,
  faFileAlt,
  faLink,
  faChalkboardTeacher,
} from "@fortawesome/free-solid-svg-icons";
// Components
import FullButton from "../Buttons/FullButton";
import "../../style/EventHeader2.css";
import BlogBox from "../Elements/BlogBox";
// import TestimonialSlider from "../Elements/TestimonialSlider";
// Assets
import imageOneToOne from "../../assets/event/imageone2one.jpg";
// import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/event/database.jpg";
import AddImage3 from "../../assets/event/communication.jpg";
import AddImage4 from "../../assets/event/budget.jpg";


const EventHeader = () => {

  const navigate = useNavigate();

  const handleButtonClick = () => {

    navigate("/contact_us");
  };
  const cards = [
    {
      icon: faLaptopCode,
      title: "Administrator",
      description:
        "Hire a dedicated and monitored event administrator responsible for planning, communication, and digital information management.",
    },
    {
      icon: faNetworkWired,
      title: "Event App",
      description:
        "It is a must-have for your next event as it reduces the need to print, allows quick access to recent event information, increases interaction, and provides monetization areas.",
    },
    {
      icon: faChalkboardTeacher,
      title: "Webmaster",
      description:
        "We cater to your needs regarding post-event activities like optimizing and uploading photos and videos to post-event galleries, designing and collecting surveys, and a thank-you e-mail to all the attendees, etc.",
    },
    {
      icon: faCalendarAlt,
      title: "Registration Form",
      description:
        "The Registration Form provides areas to collect personal and contact information of the attendees, the necessary attendance information for the event, and allows you to collect registration fees. We ensure straightforward, accurate, and lossless data collection. Plus, the system will automatically send e-mail notifications to both the delegate and event organizer on every successful registration.",
    },
    {
      icon: faUsers,
      title: "Event Website",
      description:
        "An event needs a website, which prospects can refer to for the event information, register as a delegate, get the latest event updates, and remain in touch with the event administrator. All the traffic generated by promotions will be redirected to the website; thus, an event website must have mobile-friendly, fast-loading, and recent content.",
    },
    {
      icon: faFileAlt,
      title: "Digital Promotion",
      description:
        "Whether your event is only for the network members or open to the public, you will need event promotion services to increase network members and the number of delegates attending the event. An experienced and knowledgeable team of digital marketers at One2One is ready to create a successful buzz for your upcoming event.",
    },
    {
      icon: faLink,
      title: "Event Management System (EMS)",
      description:
        "A platform that makes it easy for administrators to track delegate information and various statuses like payment, hotel booking, travel schedule, etc. It also provides a mechanism to broadcast messages to all or specific groups or individuals.",
    },
  ];
  return (
    <div className="MT-event" data-aos="fade-up">
      <div className="lightBg">
        <Wrapperr>
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h2 className="font40 extraBold">Boost Your Event</h2>
                <h5 className="">
                  We offer event websites, apps, promotion, support, and
                  management tools, ensuring that your event will grab more
                  attention digitally and provide excellent User experience
                  (UX).
                </h5>
                <ButtonsRow
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <div style={{ width: "190px" }}>
                    <button
                      type="button"
                      className="btn"
                      style={{
                        backgroundColor: "#7620ff",
                        color: "white",
                        maxWidth: "220px",
                        padding: "15px",
                        borderRadius: "8px",
                        border: "none",
                      }}
                      onClick={handleButtonClick}
                    >Contact us </button>
                  </div>

                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter w-100 ">
                    <AddImgWrapp1 className="flexCenter w-100 h-100">
                      <img src="/EventImg.jpg" alt="office " />
                    </AddImgWrapp1>
                    <AddImgWrapp2 className="w-100 h-100">
                      <img
                        src={"/boy.avif"}
                        alt="office "
                        style={{ height: "85%" }}
                      />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3 className="w-100 h-100">
                      <img
                        src={
                          "https://img.freepik.com/free-vector/social-distancing-meeting_23-2148559595.jpg?t=st=1734094899~exp=1734098499~hmac=a6c58ef1a227e1ed776c50b4c6488d423d183c7d114c016e3fb0e877f03777c3&w=1380"
                        }
                        alt="office"
                        style={{ height: "90%", marginTop: "10px" }}
                      />
                    </AddImgWrapp3>
                    <AddImgWrapp4 className="w-100 h-100">
                      <img
                        src={
                          "https://img.freepik.com/free-vector/business-meeting-scene-with-statistics_23-2147610931.jpg?t=st=1734094940~exp=1734098540~hmac=197f84a6e315867e046790746e41dfe5141aa321678c98ecaf2532c8fa095a8d&w=826"
                        }
                        alt="office"
                        style={{ height: "150%" }}
                      />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </Wrapperr>
      </div>
      <div className="text-center">
        <HeaderInfo data-aos="fade-up">
          <div className="container col-md-12">
            <h2 className="font40 extraBold mb-2">Event</h2>
            <h5 className="mb-2">
              Lots of effort and meticulous planning are required for any
              business event to be flawless and memorable for its attendees. The
              team at One2OneScheduler.com has developed an event platform or
              software tools and has extensive experience administering the
              conferences.
            </h5>
            <h5 className="">
              We do offer the below-mentioned software tools and services:
            </h5>
          </div>
        </HeaderInfo>
      </div>

      <div className="lightBg pt-5" data-aos="fade-up">
        <Wrapper id="blog">
          <div className="container">
            <div className="row justify-content-center">
              {cards.map((card, index) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center"
                  key={index}
                  style={{ marginBottom: "30px" }}
                >
                  <div className="custom-card p-4">
                    <div className="custom-card-body">
                      <div className="custom-icon-container">
                        <FontAwesomeIcon icon={card.icon} size="3x" />
                      </div>
                      <h5 className="custom-card-title">{card.title}</h5>
                      <p className="custom-card-text">{card.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  );
};
const Wrapperr = styled.section`
  margin-top: 12%;
  @media (max-width: 1200px) {
    margin-top: 8%;
  }

  @media (max-width: 992px) {
    margin-top: 25%;
  }

  @media (max-width: 768px) {
    margin-top: 30%;
  }

  @media (max-width: 576px) {
    margin-top: 35%;
  }
`;

const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  height: 380px;

  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  height: 333px;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  height: 160px;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  height: 160px;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
export default EventHeader;
