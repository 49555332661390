import React from "react";
// Sections
import TopNavbar from "../Nav/TopNavbar";
import Footer from "../Sections/Footer"

export default function Template({ children }) {
  return (
    <>
      <TopNavbar />
      {children}
      <Footer />
    </>
  );
}


