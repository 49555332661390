import React from "react";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import CollapsibleComponent from "../components/Sections/collsps";
import Contact from "../components/Sections/Contact";
import MiddleHeader from "../components/Sections/MiddleHeader";
import AnimatedCards from "../components/Sections/MeetingCards";
const Example =()=>{
    return(
    <>
    <Header /> 
    <MiddleHeader/>
    <AnimatedCards/>
    <Services /> 
    <CollapsibleComponent/>
    {/* <Projects />  */}
    <Blog />
    {/* <Pricing />  */}
    {/* <Contact />  */}
    </>
    );
}
export default Example;