import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../style/Aboutus.css";
import {
  faGlobe,
  faUserCheck,
  faHandshake,
  faChartLine,
  faClockFour,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

function AboutUs({ showAll = true }) {
  const cards = [
    {
      icon: faGlobe,
      description:
        "One2One Meeting Scheduler software is trusted and used by 60+ event organizers across 15+ countries every year.",
    },
    {
      icon: faUserCheck,
      description:
        "Honest pricing and quality support have helped us to achieve a high retention rate. i.e., around 95% of our clients have rented the scheduler multiple times year after year.",
    },
    {
      icon: faHandshake,
      description:
        "We are passionate about our work and keeps human understanding towards our client’s needs. Thus you can expect befitting replies instead of standardized replies to your queries.",
    },
    {
      icon: faChartLine,
      description:
        "We value and allocate the highest priority to our client’s feedback, which helps us to Improve & enhance our offerings.",
    },
    {
      icon: faClockFour,
      description:
        "We are offering our services for the last 10+ years and are committed to being your long-term event IT partner.",
    },
  ];

  const slider = [
    {
      que: "What We Do?",
      image:
        "https://images.pexels.com/photos/3756679/pexels-photo-3756679.jpeg?cs=srgb&dl=pexels-olly-3756679.jpg&fm=jpg",
      ans: "One2OneScheduler.com was launched in 2010 to maximize the networking benefits for the delegates and ease of organization for organizing an event through its simple and actively supported One2One Meeting Scheduler and Event Solutions.",
    },
    {
      que: "Why It Is Delegates' Favorite?",
      image:
        "https://img.freepik.com/free-photo/confident-business-lady-glasses-suit-holding-tablet-touching-chin-looking-camera-two-businessmen-working-glass-wall-copy-space-communication-concept_74855-14231.jpg",
      ans: "Through One2One Scheduler, delegates can efficiently and quickly manage their one2one meetings schedules during the event. Also, they can update their profile and review the profiles of all attendees.",
    },
    {
      que: "Why Do Event Organizers Believe In It?",
      image:
        "https://images.unsplash.com/photo-1496180470114-6ef490f3ff22?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fHByb2Zlc3Npb25hbCUyMGhkfGVufDB8fDB8fHww",
      ans: "One2One Meeting Scheduler is preferred by the event organizers since it simplifies communication and manages the delegates' schedules. Furthermore, event organizers can use a detailed post-meeting analysis report to improve and optimize upcoming events. Through Sponsorships, Booths, Preferred Meeting Locations, etc., One2One also offers monetization options.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % slider.length);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? slider.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * slider.length);
      setActiveIndex(randomIndex);
    }, 5000);
    return () => clearInterval(interval);
  }, [slider.length]);

  return (
    <div>
      <div style={{ marginTop: "120px" }} />
      {/* <section className="about-us-container">
        <div className="about-us-left">
          <div className="image-stack">
            <div className="circle-text">
              <span className="circle-number">2.98</span>
              <span className="circle-label">Finished Sessions</span>
            </div>
            <img
              src="/pexels-fauxels-3184291.jpg"
              alt="Main Student"
              className="main-image"
            />
            <img
              src="/pexels-wildlittlethingsphoto-1015568.jpg"
              alt="Sub Students"
              className="sub-image"
            />
          </div>
        </div>

        <div className="about-us-right">
          <h2>
            Empowering through knowledge and liberating through information.
          </h2>
          <p>
            Learn from experts and unlock remarkable knowledge. A small,
            committed team of professionals focused on providing learning that
            improves lives.
          </p>

          <div className="learning-stats">
            <div className="stat-item">
              <h3>90%</h3>
              <p>Students successfully graduate through completion.</p>
            </div>
            <div className="stat-item">
              <h3>9/10</h3>
              <p>Students reported better learning outcomes.</p>
            </div>
          </div>

          <button className="btn btn-primary btn-theme">Learn More →</button>
        </div>
      </section> */}
      <div className="lightBg" data-aos="fade-up">
        <section id="home" className="container pt-5 pb-5">
          <div className="row align-items-center">
            <div className="col-lg-6 d-flex flex-column justify-content-center align-items-lg-start text-center text-lg-start mb-5 mb-lg-0 mt-4">
              <h2 className="extraBold fs-1">{slider[activeIndex].que}</h2>
              <h5 className="fs-5 mb-4 mt-5">{slider[activeIndex].ans}</h5>
            </div>

            <div className="col-lg-6">
              <div
                id="imageCarousel"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  {slider.map((item, index) => (
                    <div
                      className={`carousel-item ${
                        index === activeIndex ? "active" : ""
                      }`}
                      key={index}
                    >
                      <img
                        src={item.image}
                        className="d-block w-100"
                        alt={`Slide ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "400px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ))}
                </div>

                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#imageCarousel"
                  data-bs-slide="prev"
                  onClick={handlePrev}
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>

                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#imageCarousel"
                  data-bs-slide="next"
                  onClick={handleNext}
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Wrapper id="services" data-aos="fade-up">
        <div className="whiteBg sc-dtTHTy ejVfwf">
          <div className="">
            <div className="container pt-5 ">
              <Advertising className="flexSpaceCenter">
                <AddLeft>
                  <h2 className="font40 extraBold">Company Behind One2One</h2>
                  <h6 className="">
                    One2One Meeting Scheduler and Event Solutions are developed,
                    maintained, and marketed by TechSimba, India. TechSimba
                    Private Limited is a company limited by shares registered in
                    India on 1st July 2020. The promoters of the company have
                    over 40 years combined experience in web, mobile apps
                    development, digital marketing, and IT consulting. To know
                    more about the company, please visit www.techsimba.in
                  </h6>
                  <h6>
                    Prior to Oct 2021, the One2One Scheduler was handled and
                    maintained by sister concern company Cheshta Infotech Pvt.
                    Ltd.
                  </h6>
                  <h6>
                    Our company has dedicated technical and support resources to
                    ensure that you reap the benefits of our software to the
                    fullest.
                  </h6>
                </AddLeft>

                <AddRight>
                  <AddRightInner>
                    <div className="flexNullCenter">
                      <AddImgWrapp1 className="flexCenter">
                        <img
                          src="/DALL·E 2024-12-11 15.52.16 - A professional meeting scene with diverse people in a modern office, collaborating and discussing ideas. Bright lighting, neutral tones, and sleek, mo.webp"
                          alt="office"
                        />
                      </AddImgWrapp1>
                      <AddImgWrapp2>
                        <img
                          src="/DALL·E 2024-12-11 15.56.21 - A professional meeting setting with diverse individuals seated around a conference table, engaged in discussion. The atmosphere is formal, with a mode.webp"
                          alt="office"
                        />
                      </AddImgWrapp2>
                    </div>
                    <div className="flexNullCenter">
                      <AddImgWrapp3>
                        <img
                          src="/DALL·E 2024-12-11 15.56.21 - A professional meeting setting with diverse individuals seated around a conference table, engaged in discussion. The atmosphere is formal, with a mode.webp"
                          alt="office"
                        />
                      </AddImgWrapp3>
                      <AddImgWrapp4>
                        <img
                          src="/DALL·E 2024-12-11 16.01.00 - A professional business meeting in a modern conference room. The scene includes diverse individuals in formal attire discussing ideas and collaboratin.webp"
                          alt="office"
                        />
                      </AddImgWrapp4>
                    </div>
                  </AddRightInner>
                </AddRight>
              </Advertising>
            </div>
          </div>
        </div>
      </Wrapper>
      <div className="lightBg" data-aos="fade-up">
        <div className="container pt-5">
          <h2 className="text-center meeting-title pb-5 fs-3">
            Why Choose Us ?
          </h2>
          <div className="row justify-content-center">
            {cards.slice(0, showAll ? cards.length : 6).map((card, index) => (
              <div
                className="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center"
                key={index}
              >
                <div className="custom-card p-4 w-100">
                  <div className="custom-card-body">
                    <div className="custom-icon-container">
                      <FontAwesomeIcon icon={card.icon} size="3x" />
                    </div>
                    <p className="custom-card-text">{card.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;

const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

export default AboutUs;
