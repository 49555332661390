import * as React from "react";
import one2oneImage from "../img/one2one.jpeg";
function SvgComponent(props) {
  return (
    <img
      src={one2oneImage} 
      alt="One2One"
      width={60} 
      height={50} 
      {...props} 
    />
  );
}

export default SvgComponent;
