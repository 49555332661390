import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faUsers, faVideoCamera } from '@fortawesome/free-solid-svg-icons';


const AnimatedCards = () => {
  const cards = [
    {
      icon: faVideo, 
      title: 'Virtual Meetings', 
      description: 'High-quality One2One Meetings are the best solution, whenever delegates want to attend an event from their preferred location.'
    },
    {
      icon: faUsers, 
      title: 'In-person Meetings', 
      description: 'One2One supports this since its inception; delegates will assemble at one location and meet Face to Face.'
    },
    {
      icon: faVideoCamera, 
      title: 'Hybrid Meetings', 
      description: 'This format combines in-person and virtual meetings so delegates can attend meetings via video calls while attending in-person events.'
    },
  ];

  return (
    <div className='mt-5' data-aos="fade-up">
    <div className="container-fluid bg-beige mt-5 ">
    <div className="text-center " >
      <h3 className="meeting-title p-5 fs-3">Choose The Best-Fit Meeting Type For Your Event</h3>
    </div>
  
    <div className="row justify-content-center">
      {cards.map((card, index) => (
        <div
          className="col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center"
          key={index}
          style={{ marginBottom: '30px' }}
        >
          <div className="custom-card p-4">
            <div className="custom-card-body">
              <div className="custom-icon-container">
                <FontAwesomeIcon icon={card.icon} size="3x" />
              </div>
              <h5 className="custom-card-title">{card.title}</h5>
              <p className="custom-card-text">{card.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
  </div>
  
  );
};

export default AnimatedCards;
